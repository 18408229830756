import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Address } from '../model/address.model';
import { Route } from '../model/route.model';
import { Users } from '../model/users.model';
import * as firebase from 'firebase';
import { AngularFirestore } from '@angular/fire/firestore';
import { COLLECTIONS } from "../util/constants";
import { AdminService } from './admin.service';
import { ApisService } from './apis.service';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class RoutesService {
  private db = firebase.firestore();
  routes: Route[];
  LIMIT_PAGENATION: number = 50;
  private routesSubject = new Subject<any>();
  sound = undefined;
  pages = [];
  private filter='none';
  private status;

  private unsub;
  constructor(
    private adminService:AdminService,
    private api:ApisService,
    private util:UtilService,
    private adb: AngularFirestore
  ) {
    this.sound = new Audio();
    this.sound.src = '../../assets/got-it-done-613.mp3';
    this.routes = new Array(this.LIMIT_PAGENATION);
  }

  public async init() {
    this.clearCache();
    let routeInit = {
      positionTime: new Date().setHours(23,59,59),
      page: 1,
      limit: this.LIMIT_PAGENATION
    };
    if(!this.unsub){
      this.pages.push(routeInit)
      this.listenRoutes(routeInit);
    }
  }

  filterRoutesStatus(status){
    this.clearCache();
    this.filter='status';
    let routeInit = {
      positionTime: new Date().setHours(23,59,59),
      page: 1,
      limit: this.LIMIT_PAGENATION
    };
    this.pages.push(routeInit);
    this.status=status;
    this.listenFilteredStatusroutes(routeInit,status);
  }

  getRoutes() {
    return this.routes;
  }

  getPageLimit() {
    return this.LIMIT_PAGENATION;
  }

  getPage() {
    return this.pages.length>0?this.pages[this.pages.length-1].page:1;
  }

  publishroutes(data: any) {
    this.routesSubject.next(data);
  }

  getRoutesSubject() {
    return this.routesSubject;
  }

  listenRouter(routerId) {
    return this.db.collection(COLLECTIONS.ROUTERS)
    .doc(routerId);
  }

  listenRoutes(routeInit) {
    this.unsub = this.db.collection(COLLECTIONS.ROUTERS)
    .where('operationId', '==', this.adminService.getOperationId())
    .orderBy('time', 'desc')
    .startAfter(routeInit.positionTime)
    .limit(routeInit.limit)
    .onSnapshot(async routes =>{
      const promises = routes.docChanges().map(async(snapshot:  any) =>{ 

        switch (snapshot.type) {
          case 'removed':
            this.routes.splice(snapshot.oldIndex,1)         
            break;
          case 'added':
            let newroute = await this.setRouter(snapshot.doc.data());
            if(this.routes[snapshot.newIndex]){
              this.sound.play();
              this.routes.splice(snapshot.newIndex,0,newroute)  
            }else{
              this.routes[snapshot.newIndex] = newroute;
            }
            break;
          case 'modified':
            let modroute = await this.setRouter(snapshot.doc.data());
            this.routes[snapshot.newIndex] = modroute;
            break;
        }
      });
      await Promise.all(promises);
      this.publishroutes(this.routes);
    })
  }

  listenFilteredStatusroutes(routeInit,status) {
    this.unsub = this.db.collection(COLLECTIONS.ROUTERS).where('operationId', '==', this.adminService.getOperationId()).where('status', '==', status).orderBy('time', 'desc').startAfter(routeInit.positionTime).limit(routeInit.limit).onSnapshot(async routes =>{
      const promises = routes.docChanges().map(async(snapshot:  any) =>{   
        switch (snapshot.type) {
          case 'removed':
            this.routes.splice(snapshot.oldIndex,1)         
            break;
          case 'added':
            let newroute = await this.setRouter(snapshot.doc.data());
            if(this.routes[snapshot.newIndex]){
              this.sound.play();
              this.routes.splice(snapshot.newIndex,0,newroute)  
            }else{
              this.routes[snapshot.newIndex] = newroute;
            }
            break;
          case 'modified':
            let modroute = await this.setRouter(snapshot.doc.data());
            this.routes[snapshot.newIndex] = modroute;
            break;
        }
      });
      await Promise.all(promises);
      this.publishroutes(this.routes);
    })
  }


  getAllRoutesFilter(filterParam):any{
    let routes = [];
    let filter = filterParam.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();
    
    this.getRoutesWithCondition('operationId',this.adminService.getOperationId()).then(async (data:any) =>{
      const promises = data.map(async snapshot => {
        let route = await this.setRouter(snapshot);
        if(route.control_number &&route.control_number.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase() === filter){
          routes.push(route);
        }else if(route.driver &&route.driver.fullname.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(filter)){
          routes.push(route);
        }else if(route.client&&route.client.name.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(filter)){
          routes.push(route);
        }else if(route.extraInfo && route.extraInfo.client_name && route.extraInfo.client_name.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(filter)){
          routes.push(route);
        }else if(route.extraInfo && route.extraInfo.route_number && route.extraInfo.route_number.toString().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase() === filter){
          routes.push(route);
        }
      })
        await Promise.all(promises);
      })
      return routes.sort((a,b)=>b.time-a.time);
  }

  next(pagination){
    this.unsub();
    this.routes = new Array(this.LIMIT_PAGENATION);
    pagination.limit = this.LIMIT_PAGENATION;
    this.pages.push(pagination);
    switch (this.filter) {
      case 'none':
        this.listenRoutes(pagination);
        break;
      case 'status':
        this.listenFilteredStatusroutes(pagination,this.status)
        break;
      case 'search':

        break;
    }
  }

  prev(){
    this.unsub();
    this.routes = new Array(this.LIMIT_PAGENATION);
    this.pages.pop();
    switch (this.filter) {
      case 'none':
        this.listenRoutes(this.pages[this.pages.length-1]);
        break;
      case 'status':
        this.listenFilteredStatusroutes(this.pages[this.pages.length-1],this.status)
        break;
      case 'search':

        break;
    }
    
  }
  
  async setRouter(routerData){
    const router = routerData as any;
    if (router.routerId) {
      router.client = new Users();
      router.client.address = new Address();
      router.time = router.time;
      await router.cid.get().then(async (doc)=> {
        router.client = doc.data();
      });
      if(!router.driverName){router.driverName=this.util.translate('No driver')}
    }
    return router;
  }

  public clearCache(){
    this.routes = new Array(this.LIMIT_PAGENATION);
    this.pages = [];
    this.filter='none';
    this.status = undefined;
    if(this.unsub){
      this.unsub();
      this.unsub = undefined;
    }
    this.publishroutes(this.routes);
  }

  async updateRoute(router:any){
    return await this.db.collection(COLLECTIONS.ROUTERS).doc(router.routerId).update(Object.assign({}, router));
  }

  public async getRoutesWithCondition(cond,id){
    return await this.db.collection(COLLECTIONS.ROUTERS).where(cond, '==', id).get()
    .then(async snapshot=>{
        const clients:Route[] = []
        const promise = snapshot.docs.map(routeSnapshot=> {
            const client:Route = routeSnapshot.data() as Route
            clients.push(client)
        });

        await Promise.all(promise)
        return clients;
    });
  }

  public async getroutesWithTime(cond,id,start,end): Promise<Array<Route>> {
    return await this.db.collection(COLLECTIONS.ROUTERS).where(cond, '==', id)
    .where('time', '>=', start)
    .where('time', '<=', end)
    .get()
    .then(async snapshot=>{
        const clients:Route[] = []
        const promise = snapshot.docs.map(routeSnapshot=> {
            const client:Route = routeSnapshot.data() as Route
            clients.push(client)
        });

        await Promise.all(promise)
        return clients;
    });
  }

  public async getroutesFinance(cond,id,start,end): Promise<Array<Route>> {
    return await this.db.collection(COLLECTIONS.ROUTERS).where(cond, '==', id)
    .where('status', '==', 'DELIVERED')
    .where('time', '>=', start)
    .where('time', '<=', end)
    .get()
    .then(async snapshot=>{
        const clients:Route[] = []
        const promise = snapshot.docs.map(routeSnapshot=> {
            const client:Route = routeSnapshot.data() as Route
            clients.push(client)
        });

        await Promise.all(promise)
        return clients;
    });
  }

  public getRouterLogs(routerId){
    return  this.db.collection('routerLog')
      .doc(routerId)
      .collection('all')
      .orderBy('time', 'desc')
  }

  public async setRouterLog(router, msg){
    let param ={
      routerId:router.routerId,
      userId:router.userId,
      message:msg,
      time: new Date().getTime(),
    }
    return await this.db.collection('routerLog').doc(param.routerId).collection('all').doc().set(param);
  }

  selectRouterDriver(routerId,driverId){
    return this.api.swapDeliveryDriver(routerId,driverId);
  }

  logout(){
    this.unsub();
  }
}