
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import axios from 'axios';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class ApisService {

  constructor(
    private http: HttpClient,
    private fireAuth: AngularFireAuth,
  ) {
    console.log(environment)
    axios.interceptors.request.use(async req => {
      req.headers = {
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
        'Authorization': await this.fireAuth.auth.currentUser.getIdToken()
      };
      return req;
    });
  }

  createExpressOrder(param) {
    const url = `${environment.webApi.url}/order/express`;
    //const url = "http://localhost:8080/order/express";
    const headers = {
      headers: { 'Access-Control-Allow-Origin': '*' },
    };
    return axios.post(url, param, headers)
  }

  getDistance(startPoint, endPoints) {
    const url = `${environment.webApi.url}/order/distance`;
    //const url = "http://localhost:8080/order/distance";
    const data = {
      "start": startPoint,
      "ends": endPoints
    }
    const headers = {
      headers: { 'Access-Control-Allow-Origin': '*' },
    };
    return axios.post(url, data, headers)
  }

  saveClient(client) {
    //const url = "http://localhost:8080/client";
    const url = `${environment.webApi.url}/client`;
    const headers = {
      headers: { 'Access-Control-Allow-Origin': '*' },
    };
    return axios.post(url, client, headers)
  }

  updateClient(uid, client) {
    //const url = `http://localhost:8080/client/${uid}`;
    const url = `${environment.webApi.url}/client/${uid}`;
    const headers = {
      headers: { 'Access-Control-Allow-Origin': '*' },
    };
    return axios.patch(url, client, headers)
  }

  getClients(operationId) {
    //const url = `http://localhost:8080/client?operation=${operationId}`;
    const url = `${environment.webApi.url}/client?operation=${operationId}`;
    const headers = {
      headers: { 'Access-Control-Allow-Origin': '*' },
    };
    return axios.get(url, headers)
  }

  getClient(clientId) {
    //const url = `http://localhost:8080/client/${clientId}`;
    const url = `${environment.webApi.url}/client/${clientId}`;
    const headers = {
      headers: { 'Access-Control-Allow-Origin': '*' },
    };
    return axios.get(url, headers)
  }

  deleteClient(clientId) {
    //const url = `http://localhost:8080/client/${clientId}`;
    const url = `${environment.webApi.url}/client/${clientId}`;
    const headers = {
      headers: { 'Access-Control-Allow-Origin': '*' },
    };
    return axios.delete(url, headers)
  }

  saveUser(user, type) {
    //const url = "http://localhost:8080/user" + (type ? '/' + type : '');
    const url = `${environment.webApi.url}/user${type ? '/' + type : ''}`;
    const headers = {
      headers: { 'Access-Control-Allow-Origin': '*' },
    };
    return axios.post(url, user, headers)
  }

  updateUser(uid, client, type) {
    //const url = `http://localhost:8080/user/${type ? type + '/' : ''}${uid}`;
    const url = `${environment.webApi.url}/user/${type ? type + '/' : ''}${uid}`;
    const headers = {
      headers: { 'Access-Control-Allow-Origin': '*' },
    };
    return axios.patch(url, client, headers)
  }

  getUsers(operationId, type, master) {
    //const url = `http://localhost:8080/user${type ? '/' + type : ''}?operation=${operationId}${master ? '&master=true' : ''}`;
    const url = `${environment.webApi.url}/user${type ? '/' + type : ''}?operation=${operationId}${master ? '&master=true' : ''}`;
    const headers = {
      headers: { 'Access-Control-Allow-Origin': '*' },
    };
    return axios.get(url, headers)
  }

  getUser(uid, type) {
    //const url = `http://localhost:8080/user/${type ? type + '/' : ''}${uid}`;
    const url = `${environment.webApi.url}/user/${type ? type + '/' : ''}${uid}`;
    const headers = {
      headers: { 'Access-Control-Allow-Origin': '*' },
    };
    return axios.get(url, headers)
  }

  deleteUser(uid, type) {
    //const url = `http://localhost:8080/user/${type ? type + '/' : ''}${uid}`;
    const url = `${environment.webApi.url}/user/${type ? type + '/' : ''}${uid}`;
    const headers = {
      headers: { 'Access-Control-Allow-Origin': '*' },
    };
    return axios.delete(url, headers)
  }

  swapDeliveryDriver(deliveryId, driverId) {
    //const url = `http://localhost:8080/user/swapDriver`;
    const url = `${environment.webApi.url}/user/swapDriver`;
    const body = {
      deliveryId: deliveryId,
      driverId: driverId,
    }
    const headers = {
      headers: { 'Access-Control-Allow-Origin': '*' },
    };
    return axios.post(url, body, headers);
  }

  removeDeliveryDriver(deliveryId,driverId) {
    //const url = `http://localhost:8080/user/rejectDelivery`;
    const url = `${environment.webApi.url}/user/rejectDelivery`;
    const body = {
      deliveryId: deliveryId,
      driverId:driverId
    }
    const headers = {
      headers: { 'Access-Control-Allow-Origin': '*' },
    };
    return axios.post(url, body, headers)
  }

  getDashboard(operationId) {
    //const url = `http://localhost:8080/report/dashboardAdmin/${operationId}`;
    const url = `${environment.webApi.url}/report/dashboardAdmin/${operationId}`;
    const headers = {
      headers: { 'Access-Control-Allow-Origin': '*' },
    };
    return axios.get(url, headers)
  }

  updateStatusOrder(status, orderId) {
    //const url = `http://localhost:8080/order/updateStatusOrder`;
    const url = `${environment.webApi.url}/order/updateStatusOrder`;
    const body = {
      orderId: orderId,
      status: status
    }
    const headers = {
      headers: { 'Access-Control-Allow-Origin': '*' },
    };
    return axios.post(url, body, headers)
  }

  saveOperation(operation) {
    //const url = "http://localhost:8080/operation";
    const url = `${environment.webApi.url}/operation`;
    const headers = {
      headers: { 'Access-Control-Allow-Origin': '*' },
    };
    return axios.post(url, operation, headers)
  }

  updateOperation(uid, operation) {
    //const url = `http://localhost:8080/operation/${uid}`;
    const url = `${environment.webApi.url}/operation/${uid}`;
    const headers = {
      headers: { 'Access-Control-Allow-Origin': '*' },
    };
    return axios.patch(url, operation, headers)
  }

  getOperations() {
    //const url = `http://localhost:8080/operation`;
    const url = `${environment.webApi.url}/operation`;
    const headers = {
      headers: { 'Access-Control-Allow-Origin': '*' },
    };
    return axios.get(url, headers)
  }

  getOperation(operationId) {
    //const url = `http://localhost:8080/operation/${operationId}`;
    const url = `${environment.webApi.url}/operation/${operationId}`;
    const headers = {
      headers: { 'Access-Control-Allow-Origin': '*' },
    };
    return axios.get(url, headers)
  }

  insertCredit(data,clientId) {
    //const url = `http://localhost:8080/operation/${operationId}`;
    const url = `${environment.webApi.url}/client/credit/${clientId}`;
    const headers = {
      headers: { 'Access-Control-Allow-Origin': '*' },
    };
    return axios.post(url, data, headers)
  }

}



