import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminComponent } from './pages/layouts/admin/admin.component';
import { AuthComponent } from './pages/layouts/auth/auth.component';
import { AuthGuard } from './pages/guard/auth.guard';
const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: 'admin-home',
        loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'admin-drivers',
        loadChildren: () => import('./pages/drivers/drivers.module').then(m => m.DriversModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'admin-users',
        loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'admin-orders',
        loadChildren: () => import('./pages/orders/orders.module').then(m => m.OrdersModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'admin-routers',
        loadChildren: () => import('./pages/routers/routers.module').then(m => m.RoutersModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'admin-finance',
        loadChildren: () => import('./pages/finance/finance.module').then(m => m.FinanceModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'admin-clients',
        loadChildren: () => import('./pages/clients/clients.module').then(m => m.ClientsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'admin-operation',
        loadChildren: () => import('./pages/operation/operation.module').then(m => m.OperationModule),
        canActivate: [AuthGuard]
      },
    ]
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () => import('./pages/auth/auth.module').then(m => m.AuthModule)
      },
      {
        path: 'setup',
        loadChildren: () => import('./pages/setup/setup.module').then(m => m.SetupModule)
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
