export class Address{
    address: string;
    endereco:string;
    house: string;
    id: string;
    landmark: string;
    lat: number;
    lng: number;
    title: string;
    uid: string;
    geohash:string;
}