import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  
  report: any;
  constructor(
    private toastr: ToastrService,
    private translateService: TranslateService
    ) { }
    
    
    setReport(data) {
    this.report = data;
  }

  getReport() {
    return this.report;
  }

  getCurrencyCode() {
    return environment.general.code;
  }

  getCurrecySymbol() {
    return environment.general.symbol;
  }

  makeIdImage(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  resizeBase64Img(base64, newWidth, newHeight) {
    return new Promise((resolve, reject)=>{
        var canvas = document.createElement("canvas");
        canvas.width = newWidth;
        canvas.height = newHeight;
        let context = canvas.getContext("2d");
        let img = document.createElement("img");
        img.src = base64;
        img.onload = function () {
            context.scale(newWidth/img.width,  newHeight/img.height);
            context.drawImage(img, 0, 0); 
            resolve(canvas.toDataURL());               
        }
    });
  }

  toSaveImgStorage(imgB64,ref,fileName){
    return firebase.storage().ref(ref + '/').child(btoa(fileName) + '.jpeg')
      .putString(imgB64, 'data_url');
  }

  success(msg) {
    this.toastr.success(msg, this.translate('Success'), {
      timeOut: 3000,
      positionClass: 'toast-bottom-right',
    });
  }

  error(msg) {
    this.toastr.error(msg, this.translate('Error'), {
      timeOut: 3000,
      positionClass: 'toast-bottom-right',
    });
  }

  translate(str) {
    if (!str) return;
    const currentLang = this.translateService.currentLang;
    const returnValue = this.translateService.translations[currentLang][str];
    if (returnValue === undefined) {
      return this.translateService.translations.en_merch[str];
    } else {
      return returnValue;
    }
  }

  alerts(title, message, type) {
    Swal.fire(
      title,
      message,
      type
    );
  }

  public formatDistance(value: number) {
    if(value>0.1){;
      return value.toFixed(2)+' km';
    }else{
      return (value*1000).toFixed(2)+' m';
    }
  }

}
