export const ORDER_STATUS = {
    CREATED: 'CREATED',
    NEW: "NEW",
    PENDING:"PENDING",
    PAID: "PAID",
    ACCEPTED: 'ACCEPTED',
    ONGOING: 'ONGOING',
    DRIVER_REJECTED:'DRIVER_REJECTED',
    ONSEARCHING:'ONSEARCHING',
    INPROGRESS:'INPROGRESS',
    ONROUTE: 'ONROUTE',
    GOINGBACK:'GOINGBACK',
    TAKEOUT:'TAKEOUT',
    DELIVERED: 'DELIVERED',
    COMPLETED:'COMPLETED',
    CANCELED: 'CANCELED',
    FAILED_SEARCH:'FAILED_SEARCH',
    FAILED_ROUTING:'FAILED_ROUTING',
    ROUTING:'ROUTING',
    NOT_ROUTING:'NOT_ROUTING'
}

export const ORDER_TYPE = {
    COMMOM: 'COMMOM',
    DROP_IN: 'DROP_IN',
}

export const FORMAT_PAY = {
    PAID: 'paid',
    UNINFORMED: 'uninformed',
    CARD:'card',
    MONEY: 'money',
    PIX: 'pix',
}

export const DELIVERY_TYPE={
    ORDER:'ORDER',
    ROUTER:'ROUTER'
}

export const ROUTER_ALGORITHM={
    OPTIMIZE:'OPTIMIZE',
    GROUPING:'GROUPING',
    ONE_ROUTE:'ONE_ROUTE'
}

export const ADMIN_PERMISSIONS = {
    MASTER : 'MASTER',
    ADMINISTRATIVE : 'ADMINISTRATIVE',
    LOGISTICS : 'LOGISTICS',
    COMMERCIAL : 'COMMERCIAL'
}

export const CLIENT_MODALITIES = {
    FULL : 'full',
    SMART : 'smart'
}

export const COLLECTIONS = {
    USERS: 'users',
    ORDERS: 'orders',
    ADDRESS:'address',
    REVIEWS: 'reviews',
    MERCADAPP: 'mercadappOrders',
    MERCADAPPCLIENT: 'clientMercadapp',
    CLIENT: 'client',
    OPERATION: 'operation',
    VENUE: 'venue',
    REQUEST:'request',
    ROUTERS:'routers'
}