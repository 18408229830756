import { Injectable } from '@angular/core';
import { Users } from '../model/users.model';
import { MENUITEMS } from '../shared/menu-items/menu-items';
import { ApisService } from './apis.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  user:Users;
  operationId:string;
  menu=[];

  constructor(
    private api: ApisService
  ) { 
  }

  setUser(user){
    this.user = user;
    this.operationId =user.operationId;
    this.setAvailableView();
  }

  setOperation(operationId){
    this.operationId =operationId
  }

  setAvailableView(){
    this.menu = JSON.parse(JSON.stringify(MENUITEMS)).map(menu => {
      menu.main=menu.main.filter(page=>this.user.permissions.some(perm=>page.perm.includes(perm)))
      return menu
    });
  }

  public clearCache(){
    this.user = new Users();
    this.operationId = null;
    this.menu=[];
  }

  async getDashboard(){
    return this.api.getDashboard(this.operationId);
}

  getMenu(){
    return this.menu;
  }

  getUser(){
    return this.user;
  }

  getOperationId(){
    return this.operationId;
  }

}
