import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OrdersService } from './orders.service';
import { UsersService } from './users.service';
import { AdminService } from './admin.service';
import { ApisService } from './apis.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Users } from '../model/users.model';
import { RoutesService } from './routers.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {


  constructor(    
    private ordersService: OrdersService,
    private routesService: RoutesService,
    private usersService: UsersService,
    private adminService: AdminService,
    private api: ApisService,
    private fireAuth: AngularFireAuth,
    private router: Router
    ) {

     }
  
  public async init(user) {
    this.adminService.setUser(user)
    this.ordersService.init()
    this.routesService.init();
    this.router.navigate(['admin-home']);
  }

  public async changeOperation(operationId) {
    this.adminService.setOperation(operationId)
    this.ordersService.init()
    this.routesService.init();
    this.router.navigate(['admin-home']);
  }

  public async logout(){
    const lng = localStorage.getItem('lng');
    localStorage.clear();
    localStorage.setItem('lng', lng);
    this.ordersService.clearCache();
    this.adminService.clearCache();
    await this.router.navigate(['auth/login']);
    return await this.fireAuth.auth.signOut()
  }

  public async login(email: string, password: string): Promise<any> {
    return await new Promise(async (resolve, reject) => {
    this.fireAuth.auth.signInWithEmailAndPassword(email, password)
      .then(authUser => {
        if (authUser.user) {
          this.usersService.getUser(authUser.user.uid,'admin').then((user:Users)=>{
            if (user && user.type === 'admin'){
              resolve(user);
            }else{
              reject(user)
            }
          }).catch(err=>{
            reject(err)
          })
        }else{
          reject(authUser)
        }
      }).catch(err=>{
        reject(err)
      })
    });
  }

  public async AutoLogin(): Promise<any> {
    return await new Promise(async (resolve, reject) => {
      this.fireAuth.auth.onAuthStateChanged(async authUser => {
        if(authUser){
          this.usersService.getUser(authUser.uid,'admin').then((user:Users)=>{
            if(user && user.type === 'admin'){
              this.init(user)
              resolve(user);
            }else{
              reject(user)
            }
          }).catch(err=>{
            reject(err)
          })
        }else{
          reject(authUser)
        }
      })
    });
  }

  async checkEmail(email: string): Promise<any> {
    return await this.fireAuth.auth.fetchSignInMethodsForEmail(email)
  }

  public getCurrentUser() {
    return this.fireAuth.auth.currentUser;
  }

  async resetPassword(email: string) {
    return await this.fireAuth.auth.sendPasswordResetEmail(email);
  }

}
