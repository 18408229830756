// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDv-fqxBt_GANFpwhEDD9AgU3SV-Fomjm8",
    authDomain: "simbora-teste.firebaseapp.com",
    databaseURL: "https://simbora-teste-default-rtdb.firebaseio.com",
    projectId: "simbora-teste",
    storageBucket: "simbora-teste.appspot.com",
    messagingSenderId: "439015970004",
    appId: "1:439015970004:web:94b1c51d5fff195cf7d741",
    measurementId: "G-JN4PG28JPJ"

  },
  onesignal: {
    appId: '3bcc2250-2c5e-4699-9ddc-691998ac34e4',
    googleProjectNumber: '995593874081',
    restKey: 'OWQxZmQzMDAtMjgzOC00MWFjLThmZjgtNjlhMmQ4MDI4MzE0',
    segments:['Teste'],
  },
  stripe: {
    sk: ''
  },
  general: {
    symbol: 'R$',
    code: 'BRL'
  },
  
  webApi : {
    url:'https://simbora-web-api-wjwigqb42q-uc.a.run.app'
  },
  TOKEN_KEY : 'access_simbora'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
