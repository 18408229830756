import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';
import { interval } from 'rxjs';
import { AuthService } from './services/auth.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'app';
  load:boolean=false;
  constructor(
    private router: Router,
    private translate: TranslateService,
    private swUpdate: SwUpdate,
    private authService: AuthService,
    ) {
    const lng = 'pt-br';
    if (!lng || lng === null) {
      localStorage.setItem('lng', 'pt-br');
    }
    this.translate.use(lng);
    this.checkForUpdates();
  }

  checkForUpdates(){
    this.installUpdate();
    if (this.swUpdate.isEnabled) {
      interval(6 * 60 * 60).subscribe(() => this.swUpdate.checkForUpdate()
        .then()
        );
    }
  }

  installUpdate(){
    this.swUpdate.available.subscribe(res=>{
      this.swUpdate.activateUpdate().then(res=>{
        location.reload();
      })
    })
  }

  ngOnInit() {
    this.authService.AutoLogin().then(user => {
      this.load=true
    }).catch(error => {
      this.authService.logout().then(log=>{
        console.error(error);
        this.load=true
      }).catch(err=>{
        console.error(err);
        this.load=true
      })

    });
    
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}

