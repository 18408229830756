import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Address } from '../model/address.model';
import { Order } from '../model/order.model';
import { Users } from '../model/users.model';
import * as firebase from 'firebase';
import { AngularFirestore } from '@angular/fire/firestore';
import { COLLECTIONS,DELIVERY_TYPE } from "../util/constants";
import { AdminService } from './admin.service';
import { UsersService } from './users.service';
import { UtilService } from './util.service';
import { ApisService } from "./apis.service";
@Injectable({
  providedIn: 'root'
})
export class OrdersService {
  private db = firebase.firestore();
  orders: Order[];
  LIMIT_PAGENATION: number = 50;
  private ordersSubject = new Subject<any>();
  sound = undefined;
  pages = [];
  private filter='none';
  private status;

  private unsub;
  constructor(
    private adminService:AdminService,
    private usersService:UsersService,
    private util:UtilService,
    private api:ApisService,
    private adb: AngularFirestore
  ) {
    this.sound = new Audio();
    this.sound.src = '../../assets/got-it-done-613.mp3';
    this.orders = new Array(this.LIMIT_PAGENATION);
  }

  public async init() {
    this.clearCache();
    let orderInit = {
      positionTime: new Date().setHours(23,59,59),
      page: 1,
      limit: this.LIMIT_PAGENATION
    };
    if(!this.unsub){
      this.pages.push(orderInit)
      this.listenOrders(orderInit);
    }
  }

  filterOrdersStatus(status){
    this.clearCache();
    this.filter='status';
    let orderInit = {
      positionTime: new Date().setHours(23,59,59),
      page: 1,
      limit: this.LIMIT_PAGENATION
    };
    this.pages.push(orderInit);
    this.status=status;
    this.listenFilteredStatusOrders(orderInit,status);
  }

  getOrders() {
    return this.orders;
  }

  getPageLimit() {
    return this.LIMIT_PAGENATION;
  }

  getPage() {
    return this.pages.length>0?this.pages[this.pages.length-1].page:1;
  }

  publishOrders(data: any) {
    this.ordersSubject.next(data);
  }

  getOrdersSubject() {
    return this.ordersSubject;
  }

  listenOrders(orderInit) {
    this.unsub = this.db.collection('orders')
    .where('operationId', '==', this.adminService.getOperationId())
    .orderBy('time', 'desc')
    .startAfter(orderInit.positionTime)
    .limit(orderInit.limit)
    .onSnapshot(async orders =>{
      const promises = orders.docChanges().map(async(snapshot:  any) =>{   
        switch (snapshot.type) {
          case 'removed':
            this.orders.splice(snapshot.oldIndex,1)         
            break;
          case 'added':
            let newOrder = await this.setOrder(snapshot.doc.data());
            if(this.orders[snapshot.newIndex]){
              this.sound.play();
              this.orders.splice(snapshot.newIndex,0,newOrder)  
            }else{
              this.orders[snapshot.newIndex] = newOrder;
            }
            break;
          case 'modified':
            let modOrder = await this.setOrder(snapshot.doc.data());
            this.orders[snapshot.newIndex] = modOrder;
            break;
        }
      });
      await Promise.all(promises);
      this.publishOrders(this.orders);
    })
  }

  listenFilteredStatusOrders(orderInit,status) {
    this.unsub = this.db.collection('orders').where('operationId', '==', this.adminService.getOperationId()).where('status', '==', status).orderBy('time', 'desc').startAfter(orderInit.positionTime).limit(orderInit.limit).onSnapshot(async orders =>{
      const promises = orders.docChanges().map(async(snapshot:  any) =>{   
        switch (snapshot.type) {
          case 'removed':
            this.orders.splice(snapshot.oldIndex,1)         
            break;
          case 'added':
            let newOrder = await this.setOrder(snapshot.doc.data());
            if(this.orders[snapshot.newIndex]){
              this.sound.play();
              this.orders.splice(snapshot.newIndex,0,newOrder)  
            }else{
              this.orders[snapshot.newIndex] = newOrder;
            }
            break;
          case 'modified':
            let modOrder = await this.setOrder(snapshot.doc.data());
            this.orders[snapshot.newIndex] = modOrder;
            break;
        }
      });
      await Promise.all(promises);
      this.publishOrders(this.orders);
    })
  }

  listenOrderDetail(orderId) {
    return this.db.collection(COLLECTIONS.ORDERS)
    .doc(orderId);
  }

  listenRouterOrders(routerId) {
    return this.db.collection('orders')
    .where('routerId', '==', routerId)
  }

  getAllOrdersFilter(filterParam):any{
    let orders = [];
    let filter = filterParam.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();
    
    this.getOrdersWithCondition('operationId',this.adminService.getOperationId()).then(async (data:any) =>{
      const promises = data.map(async snapshot => {
        let order = await this.setOrder(snapshot);
        if(order.control_number &&order.control_number.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase() === filter){
          orders.push(order);
        }else if(order.driver &&order.driver.fullname.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(filter)){
          orders.push(order);
        }else if(order.client&&order.client.name.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(filter)){
          orders.push(order);
        }else if(order.extraInfo && order.extraInfo.client_name && order.extraInfo.client_name.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase().includes(filter)){
          orders.push(order);
        }else if(order.extraInfo && order.extraInfo.order_number && order.extraInfo.order_number.toString().normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase() === filter){
          orders.push(order);
        }
      })
        await Promise.all(promises);
      })
      return orders.sort((a,b)=>b.time-a.time);
  }

  next(pagination){
    this.unsub();
    this.orders = new Array(this.LIMIT_PAGENATION);
    pagination.limit = this.LIMIT_PAGENATION;
    this.pages.push(pagination);
    switch (this.filter) {
      case 'none':
        this.listenOrders(pagination);
        break;
      case 'status':
        this.listenFilteredStatusOrders(pagination,this.status)
        break;
      case 'search':

        break;
    }
  }

  prev(){
    this.unsub();
    this.orders = new Array(this.LIMIT_PAGENATION);
    this.pages.pop();
    switch (this.filter) {
      case 'none':
        this.listenOrders(this.pages[this.pages.length-1]);
        break;
      case 'status':
        this.listenFilteredStatusOrders(this.pages[this.pages.length-1],this.status)
        break;
      case 'search':

        break;
    }
    
  }
  
  async setOrder(orderData){
    const order = orderData as any;
    if (order.orderId) {
      order.client = new Users();
      order.client.address = new Address();
      order.time = order.time;
      await order.uid.get().then(function (doc) {
        order.client = doc.data();
      });

      if (order.driverId && !order.driverName) {
        const driver = await this.usersService.getUser(order.driverId,'driver');
        if(driver){
          const fullname = driver.fullname.split(' ');
          order.driverName = `${fullname[0]} ${fullname[fullname.length - 1]}`;
        }else{
          order.driverName= this.util.translate('No driver');
        }
      }else if(!order.driverId){
        order.driverName= this.util.translate('No driver');
      }

    }
    return order;
  }

  public clearCache(){
    this.orders = new Array(this.LIMIT_PAGENATION);
    this.pages = [];
    this.filter='none';
    this.status = undefined;
    if(this.unsub){
      this.unsub();
      this.unsub = undefined;
    }
    this.publishOrders(this.orders);
  }

  async getOrder(orderId){
    return await this.db.collection(COLLECTIONS.ORDERS).doc(orderId).get()
    .then(snapshot=>{
        const client = snapshot.data() as Order;
        return client;
    })
  };

  async updateOrder(order:any){
    return await this.db.collection(COLLECTIONS.ORDERS).doc(order.orderId).update(Object.assign({}, order));
  }

  public async getOrdersWithCondition(cond,id){
    return await this.db.collection(COLLECTIONS.ORDERS).where(cond, '==', id).get()
    .then(async snapshot=>{
        const clients:Order[] = []
        const promise = snapshot.docs.map(orderSnapshot=> {
            const client:Order = orderSnapshot.data() as Order
            clients.push(client)
        });

        await Promise.all(promise)
        return clients;
    });
  }

  public async getOrdersWithTime(cond,id,start,end): Promise<Array<Order>> {
    return await this.db.collection(COLLECTIONS.ORDERS).where(cond, '==', id)
    .where('time', '>=', start)
    .where('time', '<=', end)
    .get()
    .then(async snapshot=>{
        const clients:Order[] = []
        const promise = snapshot.docs.map(orderSnapshot=> {
            const client:Order = orderSnapshot.data() as Order
            clients.push(client)
        });

        await Promise.all(promise)
        return clients;
    });
  }

  public async getOrdersFinance(cond,id,start,end): Promise<Array<Order>> {
    return await this.db.collection(COLLECTIONS.ORDERS).where(cond, '==', id)
    .where('status', '==', 'DELIVERED')
    .where('time', '>=', start)
    .where('time', '<=', end)
    .get()
    .then(async snapshot=>{
        const clients:Order[] = []
        const promise = snapshot.docs.map(orderSnapshot=> {
            const client:Order = orderSnapshot.data() as Order
            clients.push(client)
        });

        await Promise.all(promise)
        return clients;
    });
  }

  public getOrderLogs(orderId){
    return this.db.collection('orderLog')
      .doc(orderId)
      .collection('all')
      .orderBy('time', 'desc');
  }
  public getPreviousOrder(order){
    return this.db.collection(COLLECTIONS.ORDERS)
    .where('operationId', '==', this.adminService.getOperationId())
    .orderBy('time')
    .startAfter(order.time)
    .limit(1)
    .get()
    .then(async snapshot=>{
      const orders:Order[] = []
      const promise = snapshot.docs.map(orderSnapshot=> {
          const order:Order = orderSnapshot.data() as Order
          orders.push(order)
      });

      await Promise.all(promise)
      return orders[0];
    });
  }
  public getNextOrder(order){
    return this.db.collection(COLLECTIONS.ORDERS)
      .where('operationId', '==', this.adminService.getOperationId())
      .orderBy('time', 'desc')
      .startAfter(order.time)
      .limit(1)
      .get()
      .then(async snapshot=>{
        const orders:Order[] = []
        const promise = snapshot.docs.map(orderSnapshot=> {
            const order:Order = orderSnapshot.data() as Order;

            orders.push(order)
        });
  
        await Promise.all(promise)
        return orders[0];
      });
  }

  public async setOrdeLog(order, msg){
    let param ={
      orderId:order.orderId,
      userId:order.userId,
      venueId:order.vid,
      message:msg,
      time: new Date().getTime(),
    }
    return await this.db.collection('orderLog').doc(param.orderId).collection('all').doc().set(param);
  }

  async updateStatusOrder(status,orderId){
    return this.api.updateStatusOrder(status,orderId);
  }

  logout(){
    this.unsub();
  }
}