import { Injectable } from '@angular/core';
import * as firebase from 'firebase';
import { Users } from '../model/users.model';
import { AngularFirestore } from '@angular/fire/firestore';
import { COLLECTIONS } from '../util/constants';
import { ApisService } from './apis.service';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private db = firebase.firestore();

  constructor(
    private adb: AngularFirestore,
    private api: ApisService
  ) { }

  public async getUsers(operationId, type, master = false) {
    return await this.api.getUsers(operationId, type, master).then(async result => {
      return result.data.data as Users[];
    });
  }

  public async getAvailableDrivers(clientId) {
    return await this.db.collection("users")
      .where('type', '==', 'delivery')
      .where('status', '==', 'active')
      .where('current', '==', 'available')
      .where('clientId', '==', clientId)
      .where('online', '==', true)
      .get()
      .then(async snapshot => {
        const users: Users[] = []
        const promise = snapshot.docs.map(userSnapshot => {
          const user: Users = userSnapshot.data() as Users
          users.push(user);
        });

        await Promise.all(promise);
        return users;
      });
  }

  async saveUser(user: Users, type) {
    return await this.api.saveUser(user, type);
  }

  async updateUser(uid, user: any, type) {
    return await this.api.updateUser(uid, user, type);
  }

  async deleteUser(uid: string, type) {
    return await this.api.deleteUser(uid, type);
  }

  async getUser(uid, type) {
    console.log('get user',uid, type)
    return await this.api.getUser(uid, type).then(async result => {
      return result.data.data as Users;
    });
  };

  async getRequests(type, condition) {
    return await this.db.collection(COLLECTIONS.REQUEST).where('type', '==', type).where(condition.type, '==', condition.value).get()
      .then(async snapshot => {
        const requests: any[] = [];
        const promise = snapshot.docs.map(requestSnapshot => {
          const request: any = requestSnapshot.data() as Users
          requests.push(request);
        });
        await Promise.all(promise);
        return requests;
      });
  }

  async updateRequest(request: any) {
    return await this.db.collection(COLLECTIONS.REQUEST).doc(request.requestId).update(Object.assign({}, request));
  }

  public getDrivers(operationId) {
    return this.db.collection(COLLECTIONS.USERS).where('type', '==', 'delivery').where('operationId', '==', operationId);
  }

  async removeRouterDriver(routerId,driverId) {
    return this.api.removeDeliveryDriver(routerId,driverId);
  }

}
