import { Injectable } from "@angular/core";
import { Operation } from "../model/operation.model";
import { ApisService } from "./apis.service";

@Injectable({
    providedIn: 'root'
  })
  export class OperationService {
    constructor(
        private api:ApisService
        ) { }
    async saveOperation(operation:Operation){
        return await this.api.saveOperation(operation);
    }

    async updateOperation(operation:Operation){
        return await this.api.updateOperation(operation.uid,operation);
    }

    async getOperations(){
        return await this.api.getOperations().then(async result=>{
            return result.data.data as Operation[];
        });
    }

    async getOperation(uid){
        return await this.api.getOperation(uid).then(async result=>{
            return result.data.data as Operation;
        });
    }
  }
  