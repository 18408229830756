<div class="page-header">
  <div class="page-header-title">
    <span *ngFor="let breadcrumb of breadcrumbs; let last = last">
      <h4 *ngIf="last">{{ breadcrumb.label | translate}}</h4>
    </span>
  </div>
  <div class="page-header-breadcrumb">
    <ul class="breadcrumb">
      <li class="breadcrumb-item">
        <a [routerLink]="'/'">
          <i class="icofont icofont-home"></i>
        </a>
      </li>
      <li class="breadcrumb-item" *ngFor="let breadcrumb of breadcrumbs">
        <a [ngClass]="breadcrumb.status === false ? 'not-active': ''"
          [routerLink]="breadcrumb.url">{{breadcrumb.label | translate}}</a>
      </li>
    </ul>
  </div>
</div>