import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(
        private authServ: AuthService,
        private router: Router,
    ) { }

    canActivate(route: ActivatedRouteSnapshot): any {
        // /// You can use this one for better security
        if(this.authServ.getCurrentUser() && this.authServ.getCurrentUser().uid){
            return true;
        }else{
            this.router.navigate(['/auth/login']);
            return false;
        }

        /*
        return this.authServ.getCurrentUser().then(user => {
            if (user && user.uid) {
                return true;
            } else {
                this.authServ.logout()
            }
        }).catch(error => {
            console.error(error);
            this.authServ.logout()
        });*/
        

        // ///// Less Secure but faster
        // const uid = localStorage.getItem('uid');
        // if (uid && uid != null && uid !== 'null') {
        //     return true;
        // }
        // this.router.navigate(['/auth/login']);
        // return false;
    }
}
