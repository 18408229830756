import { Injectable } from '@angular/core';
import { ADMIN_PERMISSIONS } from 'src/app/util/constants';
export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  target?: boolean;
  name: string;
  type?: string;
  children?: ChildrenItems[];
}

export interface MainMenuItems {
  state: string;
  main_state?: string;
  target?: boolean;
  name: string;
  type: string;
  icon: string;
  label: string;
  perm:String[];
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

export interface Menu {
  label: string;
  main: MainMenuItems[];
}
export const MENUPAGES:Array<MainMenuItems> = [
  {
    state: 'admin-home',
    name: 'Home',
    type: 'link',
    icon: 'ti-home',
    label:'Main',
    perm:[ADMIN_PERMISSIONS.MASTER,
      ADMIN_PERMISSIONS.ADMINISTRATIVE,
      ADMIN_PERMISSIONS.LOGISTICS,
      ADMIN_PERMISSIONS.COMMERCIAL
    ]
  },
  {
    state: 'dashboard',
    main_state:'admin-orders',
    name: 'Dashboard',
    type: 'link',
    icon: 'ti-bar-chart-alt',
    label:'Main',
    perm:[ADMIN_PERMISSIONS.MASTER,
      ADMIN_PERMISSIONS.ADMINISTRATIVE,
      ADMIN_PERMISSIONS.LOGISTICS,
      ADMIN_PERMISSIONS.COMMERCIAL
    ]
  },
  {
    state: 'express',
    main_state:'admin-orders',
    name: 'Express',
    type: 'link',
    icon: 'ti-rocket',
    label:'Main',
    perm:[ADMIN_PERMISSIONS.MASTER,
      ADMIN_PERMISSIONS.ADMINISTRATIVE,
      ADMIN_PERMISSIONS.LOGISTICS,
      ADMIN_PERMISSIONS.COMMERCIAL
    ]
  },
  {
    state: 'admin-clients',
    name: 'Clients',
    type: 'link',
    icon: 'ti-notepad',
    label:'Main',
    perm:[ADMIN_PERMISSIONS.MASTER,
      ADMIN_PERMISSIONS.ADMINISTRATIVE,
      ADMIN_PERMISSIONS.COMMERCIAL
    ]
  },
  {
    state: 'admin-operation',
    name: 'Operations',
    type: 'link',
    icon: 'ti-crown',
    label:'Main',
    perm:[ADMIN_PERMISSIONS.MASTER
    ]
  },
  {
    state: 'admin-users',
    name: 'Users',
    type: 'link',
    icon: 'ti-user',
    label:'Main',
    perm:[ADMIN_PERMISSIONS.MASTER,
      ADMIN_PERMISSIONS.ADMINISTRATIVE
    ]
  },
  {
    state: 'admin-drivers',
    name: 'Drivers',
    type: 'link',
    icon: 'ti-truck',
    label:'Main',
    perm:[ADMIN_PERMISSIONS.MASTER,
      ADMIN_PERMISSIONS.ADMINISTRATIVE,
      ADMIN_PERMISSIONS.LOGISTICS,
      ADMIN_PERMISSIONS.COMMERCIAL
    ]
  },
  {
    state: 'admin-orders',
    name: 'Orders',
    type: 'link',
    icon: 'ti-shopping-cart',
    label:'Main',
    perm:[ADMIN_PERMISSIONS.MASTER,
      ADMIN_PERMISSIONS.ADMINISTRATIVE,
      ADMIN_PERMISSIONS.LOGISTICS,
      ADMIN_PERMISSIONS.COMMERCIAL
    ]
  },
  {
    state: 'admin-routers',
    name: 'Routes',
    type: 'link',
    icon: 'ti-shopping-cart',
    label:'Main',
    perm:[ADMIN_PERMISSIONS.MASTER,
      ADMIN_PERMISSIONS.ADMINISTRATIVE,
      ADMIN_PERMISSIONS.LOGISTICS,
      ADMIN_PERMISSIONS.COMMERCIAL
    ]
  },
  {
    state: 'admin-finance',
    name: 'Finance',
    type: 'link',
    icon: 'ti-money',
    label:'Main',
    perm:[ADMIN_PERMISSIONS.MASTER,
      ADMIN_PERMISSIONS.ADMINISTRATIVE,
      ADMIN_PERMISSIONS.COMMERCIAL
    ]
  },
];
export const MENUITEMS:Array<Menu> = [
  {
    label: 'Main',
    main: [
      {
        state: 'admin-home',
        name: 'Home',
        type: 'link',
        icon: 'ti-home',
        label:'Main',
        perm:[ADMIN_PERMISSIONS.MASTER,
          ADMIN_PERMISSIONS.ADMINISTRATIVE,
          ADMIN_PERMISSIONS.LOGISTICS,
          ADMIN_PERMISSIONS.COMMERCIAL
        ]
      },
      {
        state: 'dashboard',
        main_state:'admin-orders',
        name: 'Dashboard',
        type: 'link',
        icon: 'ti-bar-chart-alt',
        label:'Main',
        perm:[ADMIN_PERMISSIONS.MASTER,
          ADMIN_PERMISSIONS.ADMINISTRATIVE,
          ADMIN_PERMISSIONS.LOGISTICS,
          ADMIN_PERMISSIONS.COMMERCIAL
        ]
      },
      {
        state: 'express',
        main_state:'admin-orders',
        name: 'Express',
        type: 'link',
        icon: 'ti-rocket',
        label:'Main',
        perm:[ADMIN_PERMISSIONS.MASTER,
          ADMIN_PERMISSIONS.ADMINISTRATIVE,
          ADMIN_PERMISSIONS.LOGISTICS,
          ADMIN_PERMISSIONS.COMMERCIAL
        ]
      },
      {
        state: 'admin-clients',
        name: 'Clients',
        type: 'link',
        icon: 'ti-notepad',
        label:'Main',
        perm:[ADMIN_PERMISSIONS.MASTER,
          ADMIN_PERMISSIONS.ADMINISTRATIVE,
          ADMIN_PERMISSIONS.COMMERCIAL
        ]
      },
      {
        state: 'admin-operation',
        name: 'Operations',
        type: 'link',
        icon: 'ti-crown',
        label:'Main',
        perm:[ADMIN_PERMISSIONS.MASTER
        ]
      },
      {
        state: 'admin-users',
        name: 'Users',
        type: 'link',
        icon: 'ti-user',
        label:'Main',
        perm:[ADMIN_PERMISSIONS.MASTER,
          ADMIN_PERMISSIONS.ADMINISTRATIVE
        ]
      },
      {
        state: 'admin-drivers',
        name: 'Drivers',
        type: 'link',
        icon: 'ti-truck',
        label:'Main',
        perm:[ADMIN_PERMISSIONS.MASTER,
          ADMIN_PERMISSIONS.ADMINISTRATIVE,
          ADMIN_PERMISSIONS.LOGISTICS,
          ADMIN_PERMISSIONS.COMMERCIAL
        ]
      },
      {
        state: 'admin-orders',
        name: 'Orders',
        type: 'link',
        icon: 'ti-shopping-cart',
        label:'Main',
        perm:[ADMIN_PERMISSIONS.MASTER,
          ADMIN_PERMISSIONS.ADMINISTRATIVE,
          ADMIN_PERMISSIONS.LOGISTICS,
          ADMIN_PERMISSIONS.COMMERCIAL
        ]
      },
      {
        state: 'admin-routers',
        name: 'Routes',
        type: 'link',
        icon: 'ti-shopping-cart',
        label:'Main',
        perm:[ADMIN_PERMISSIONS.MASTER,
          ADMIN_PERMISSIONS.ADMINISTRATIVE,
          ADMIN_PERMISSIONS.LOGISTICS,
          ADMIN_PERMISSIONS.COMMERCIAL
        ]
      },
      {
        state: 'admin-finance',
        name: 'Finance',
        type: 'link',
        icon: 'ti-money',
        label:'Main',
        perm:[ADMIN_PERMISSIONS.MASTER,
          ADMIN_PERMISSIONS.ADMINISTRATIVE,
          ADMIN_PERMISSIONS.COMMERCIAL
        ]
      },
    ],
  },
  {
    label: 'Manage',
    main: [
    ]
  },
];
@Injectable()
export class MenuItems {
  getAll(permissions): Menu[] {
    let pages = []
    let menu = MENUITEMS
    MENUPAGES.forEach(page => {
      if(permissions.some(perm=>page.perm.includes(perm))){
       pages.push(page);
      }
    });
    menu[0].main = pages;
    return menu;
  }

}
