import { Address } from "./address.model";

export class Users{
    coverImage: string = '';
    current: string;
    descriptions: string;
    email: string;
    fcm_token: string;
    fullname: string = '';
    mercFullname: string = '';
    name: string = '';
    id: string;
    lat: number;
    lng: number;
    online: boolean;
    phone: string;
    tel: string;
    restaurant:{id: number; name: string}
    status: string;
    type: string = 'admin';
    uid: string;
    address: Address = new Address();
    password: string;
    permissions: string[] = [];
    reviews: any[] = [];
    priority: number;
    token: string;
    operationId:Array<String>;
    defaultOp:string;
    cpfcnpj:string;
    clientId:Array<String>;
    vehicle:string;
}